*, :after, :before {
  box-sizing: border-box;
  cursor: none;
}

html, body, #Root {
  height: 100%;
  margin: 0;
}

.Next {
  appearance: none;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  inset: 0;
}

.Caption {
  text-align: center;
  color: #fff;
  mix-blend-mode: difference;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1;
  display: flex;
  position: fixed;
  inset: 0;
}

.Caption > span {
  padding: .25em .5em .5em;
}

.Of {
  width: 100%;
  height: 100%;
}

.Within {
  height: 100%;
}

.Within--a {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.Within--b {
  width: 75%;
  height: 75%;
}

.Beside {
  width: 100%;
  height: 100%;
  display: flex;
}

.Beside--a, .Beside--b {
  flex: 1;
}

.On {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.On > div, .On--a, .On--b {
  flex: 1;
}

/*# sourceMappingURL=index.ed5f4641.css.map */
