*,
*:after,
*:before {
  box-sizing: border-box;
  cursor: none;
}

html,
body,
#Root {
  height: 100%;
  margin: 0;
}

.Next {
  appearance: none;
  padding: 0;
  border: 0;
  margin: 0;
  background-color: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Caption {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  // font-family: "Union", "Helvetica Neue", Helvetica, Arial, sans-serif; // TODO
  mix-blend-mode: difference;
  // cursor: default;
  user-select: none;
  line-height: 1;

  > span {
    padding: 0.25em 0.5em 0.5em 0.5em;
  }
}

.Of {
  width: 100%;
  height: 100%;
}

.Within {
  height: 100%;

  &--a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--b {
    width: 75%;
    height: 75%;
  }
}

.Beside {
  display: flex;
  width: 100%;
  height: 100%;

  &--a,
  &--b {
    flex: 1;
  }
}

.On {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  > div,
  &--a,
  &--b {
    flex: 1;
  }
}